.rc-rate {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 18px;
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  font-style: normal;
  outline: none;
}
.rc-rate-rtl {
  direction: rtl;
}
.rc-rate-disabled .rc-rate-star {
  cursor: default;
}
.rc-rate-disabled .rc-rate-star:before,
.rc-rate-disabled .rc-rate-star-content:before {
  cursor: default;
}
.rc-rate-disabled .rc-rate-star:hover {
  transform: scale(1);
}
.rc-rate-star {
  margin: 0;
  padding: 0;
  display: inline-block;
  margin-right: 8px;
  position: relative;
  transition: all 0.3s;
  color: #e9e9e9;
  cursor: pointer;
  line-height: 1.5;
}
.rc-rate-rtl .rc-rate-star {
  margin-right: 0;
  margin-left: 8px;
  float: right;
}
.rc-rate-star-first,
.rc-rate-star-second {
  transition: all 0.3s;
}
.rc-rate-star-focused,
.rc-rate-star:hover {
  transform: scale(1.1);
}
.rc-rate-star-first {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}
.rc-rate-rtl .rc-rate-star-first {
  right: 0;
  left: auto;
}
.rc-rate-star-half .rc-rate-star-first,
.rc-rate-star-half .rc-rate-star-second {
  opacity: 1;
}
.rc-rate-star-half .rc-rate-star-first,
.rc-rate-star-full .rc-rate-star-second {
  color: #f5a623;
}
.rc-rate-star-half:hover .rc-rate-star-first,
.rc-rate-star-full:hover .rc-rate-star-second {
  color: #f8c165;
}
@font-face {
  font-family: 'anticon';
  src: url('//at.alicdn.com/t/font_r5u29ls31bgldi.eot');
  /* IE9*/
  src: url('//at.alicdn.com/t/font_r5u29ls31bgldi.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('//at.alicdn.com/t/font_r5u29ls31bgldi.woff') format('woff'), /* chrome、firefox */ url('//at.alicdn.com/t/font_r5u29ls31bgldi.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/ url('//at.alicdn.com/t/font_r5u29ls31bgldi.svg#iconfont') format('svg');
  /* iOS 4.1- */
}
.anticon {
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  line-height: 1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon:before {
  display: block;
  font-family: "anticon" !important;
}
.anticon-star:before {
  content: "\e660";
}
